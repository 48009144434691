<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div class="page">
    <b-container fluid>
      <b-overlay
        no-wrap
        :show="loading"
        :variant="'white'"
        :opacity="0.7"
        :blur="'2px'"
        rounded="sm"
        style="z-index: 1041;"
      />
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-row>
          <b-col
            cols="8"
            class="detail-forms"
            style="overflow-x: hidden;"
          >
            <BackLink
              route-name="Datasets"
              :title="capitalizeFirstLetter($tc('words.dataset', 2))"
            />
            <ValidationObserver v-slot="{ handleSubmit }">
              <form>
                <!-- TOP ANCHOR FOR SPECIFIC COMPONENTS -->
                <div id="dataset-detail-top-anchor">
                  <component
                    :is="specificComponent.component"
                    v-for="[i, specificComponent] of topAnchoredSpecificComponents.entries()"
                    :key="`top-specific-component-${i}`"
                  />
                </div>
                <DatasetForm
                  ref="datasetForm"
                  :mode="'datasetDetail'"
                  :default-form="form"
                  @load="setLoader"
                  @change="setForm"
                  @remove-contact="removeContact"
                />
                
                <!-- BOTTOM ANCHOR FOR SPECIFIC COMPONENTS -->
                <div id="dataset-detail-bottom-anchor">
                  <component
                    :is="specificComponent.component"
                    v-for="[i, specificComponent] of bottomAnchoredSpecificComponents.entries()"
                    :key="`bottom-specific-component-${i}`"
                  />
                </div>

                <h3
                  class="line-header"
                >
                  <img
                    src="@/assets/icons/file_document_sheet.svg"
                    alt="Icon files ressources"
                  > {{ $t('datasetDetail.mainResource.title') }}
                </h3>
                <b-row style="height: auto;">
                  <b-col
                    cols="12"
                  >
                    <MainResource
                      :loading="loadingResource"
                      @set="setResource"
                      @modify="startModifyResource"
                      @update="startUpdateResource"
                      @delete="afterResourceChange"
                      @tasks="getLastDatasetTasks"
                    />
                    <div
                      v-if="!currentDatasetMainResource || (currentDatasetMainResource && !currentDatasetMainResource.id)"
                      class="form-footer"
                      style="margin: 1em 0 0 0; justify-content: flex-end;"
                    >
                      <b-button
                        variant="primary"
                        :disabled="canLoadSource"
                        style="font-size: 1em;"
                        @click="handleSubmit(startCreateResource)"
                      >
                        {{ $t('buttons.load') }}
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
                <h3
                  class="line-header"
                >
                  <img
                    src="@/assets/icons/files_ressources.svg"
                    alt="Icon files ressources"
                  > {{ $t('datasetDetail.annexes.title') }}
                </h3>
                <b-row style="height: auto;">
                  <b-col
                    cols="12"
                  >
                    <Annexes
                      ref="annexesComp"
                      :loading-id="annexeLoadingId"
                      @modify="startModifyResource"
                      @update="startUpdateResource"
                      @delete="afterResourceChange"
                    />
                    <AddAnnexe
                      v-if="isAddingAnnexe"
                      :required="true"
                      @close="afterResourceChange"
                    />
                    <button
                      v-if="isAddingAnnexe"
                      class="add-button"
                      @click.prevent="isAddingAnnexe = false"
                    >
                      <b-icon-dash />
                      {{ $t('buttons.cancel') }}
                    </button>
                    <button
                      v-else
                      class="add-button"
                      @click.prevent="isAddingAnnexe = true"
                    >
                      <b-icon-plus />
                      {{ $t('datasetDetail.annexes.add') }}
                    </button>
                  </b-col>
                </b-row>

                <h3
                  v-if="currentDataset.children && currentDataset.children.length"
                  class="line-header"
                >
                  <img
                    src="@/assets/icons/files_ressources.svg"
                    alt="Icon form description file"
                  > {{ $t('forms.datasets.children.title') }}
                </h3>

                <b-row
                  v-if="currentDataset.children && currentDataset.children.length"
                  style="height: auto;"
                >
                  <b-col>
                    <table class="table table-bordered table-hover align-middle">
                      <thead class="table-dark">
                        <tr>
                          <th scope="col">
                            {{ $t('datasetDetail.childrenTable.fields.name') }}
                          </th>
                          <th scope="col">
                            <i
                              class="bi bi-link"
                              :style="{ 'font-size': '1.2rem' }"
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="child in currentDataset.children"
                          :key="`dataset-children-table-row-${child.id}`"
                          @click="$router.push({
                            name: 'DatasetDetail',
                            params: {
                              id: child.id
                            }
                          })"
                        >
                          <td>
                            {{ child.display_name }}
                          </td>
                          <td>
                            <a
                              class="nav-item"
                              :href="openDatasetInPortal(child.codename)"
                              target="_blank"
                              @click.stop
                            >
                              {{ $t('datasetDetail.childrenTable.openInPortal') }}
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </b-col>
                </b-row>
              </form>
            </ValidationObserver>
          </b-col>
          <b-col
            cols="4"
            class="detail-card"
          >
            <DatasetRightSideBar
              :usergroup="form.usergroup"
              @set-thumbnail="setThumbnail"
              @set-recordtype="setDatasetRecordType"
              @set-usergroup="setUsergroup"
              @publish="runDatasetPublishScenario"
              @duplicate="duplicateDataset"
              @save="handleSubmit(saveDataset)"
            />
          </b-col>
        </b-row>
      </ValidationObserver>
    </b-container>
    <SelectScenario
      :open="selectingScenario"
      :context="selectingScenarioContext"
      :scenarios="possibleScenarios"
      @create="finishCreateResource"
      @modify="finishModifyResource"
      @update="finishUpdateResource"
    />
    <CustomToast
      v-for="task of currentTasks"
      ref="custom-toast"
      :key="`${task.id}-${task.state}`"
      :auto-hide="task.state !== 'PENDING'"
      :task="task"
      @hide="hideToast"
    />
  </div>
</template>

<script>
import  { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import datasetsAPI from '@/api/datasetsAPI.js';
import dashboardAPI from '@/api/dashboardAPI.js';

import { ErrorService } from '@/services/error-service.js';

import { COVERAGE_OPTIONS } from '@/constants.js';

import AddAnnexe from '@/components/Forms/AddAnnexe.vue';
import Annexes from '@/components/Resource/Annexes.vue';
import BackLink from '@/components/Elements/BackLink.vue';
import CustomToast from '@/components/CustomToast.vue';
import DatasetCard from '@/components/Cards/DatasetCard.vue';
import DatasetForm from '@/components/Forms/DatasetForm.vue';
import DatasetRightSideBar from '@/components/Dataset/DatasetRightSideBar.vue';
import MainResource from '@/components/Resource/MainResource.vue';
import SelectScenario from '@/components/Modals/SelectScenario.vue';
import SearchablePaginatedTable from '@/components/SearchablePaginatedTable/Layout.vue';

import { ValidationObserver } from 'vee-validate';

export default {
  name: 'DatasetDetail',

  components: {
    AddAnnexe,
    Annexes,
    BackLink,
    CustomToast,
    DatasetCard,
    DatasetForm,
    DatasetRightSideBar,
    MainResource,
    SearchablePaginatedTable,
    SelectScenario,
    ValidationObserver,
  },

  data() {
    return {
      loading: false,
      loadingResource: false,
      annexeLoadingId: null,
      disablingResource: false,

      form: {
        display_name: null,
        description: null,
        keywords: [],
        categories: [],
        topics: [],
        kinds: [],
        recommendation: null,
        restriction: null,
        usergroup: null,
        parent: null,
        recordType: null,
        datasetRoles: [],
        licence: null,
        copyright: null,
        granularity: null,
        coverage: null,
        frequence: null,
        publicationDate: null,
        lastUpdateDate: null,
        creationDate: null,
        revisionDate: null,
        year: null,
        lineage: null,
        resource: {
          format: null,
          displayName: null,
          description: null,
          file: null
        },
        thumbnail: null
      },
      newResource: null,
      modifyResource: null,
      updateResource: null,
      isAddingAnnexe: false,

      // scenario
      selectingScenario: false,
      selectingScenarioContext: null,
      possibleScenarios: [],
      selectedScenario: null,

      //task toast
      lastTasks: null,
      currentTasks: [],
      hiddenTasksIds: []
    };
  },

  computed: {
    ...mapState('datasets', [
      'currentDataset',
      'currentDatasetRoles',
      'datasetsCategories',
      'datasetsTopics',
      'datasetsRestrictions',
      'datasetsKinds',
      'datasetsUpdateFrequencies',
    ]),
    ...mapState('organisations', [
      'organisationsList'
    ]),
    ...mapState('resources', [
      'currentResourceScenarios',
      'lastCreatedResource'
    ]),
    ...mapState('form', [
      'originalForm'
    ]),
    ...mapState('dashboard', [
      'dashboardTasksQueue'
    ]),
    ...mapGetters('datasets', [
      'currentDatasetPublishState',
      'currentDatasetPublishScenario',
      'currentDatasetUnpublishScenario',
      'currentDatasetExtras'
    ]),
    ...mapGetters('resources', [
      'currentDatasetMainResource',
      'currentDatasetAnnexResources'
    ]),

    /** specific components */
    specificComponents() {
      if (this.$config.components && Object.keys(this.$config.components).length) {
        return Object.entries(this.$config.components).map(el => {
          return {
            component: () => import(`../../../public/specific/components/${el[0]}.vue`),
            anchor: el[1]
          };
        });
      }
      return [];
    },
    topAnchoredSpecificComponents() {
      return this.specificComponents.filter(el => el.anchor === 'dataset-detail-top-anchor');
    },
    bottomAnchoredSpecificComponents() {
      return this.specificComponents.filter(el => el.anchor === 'dataset-detail-bottom-anchor');
    },
    /** */

    buildDatasetIndexScenarioId() {
      if (
        this.currentDataset &&
        this.currentDatasetPublishScenario &&
        this.currentDatasetPublishScenario.id &&
        this.currentDatasetUnpublishScenario &&
        this.currentDatasetUnpublishScenario.id
      ) {
        return this.currentDatasetPublishState ?
          this.currentDatasetPublishScenario.id :
          this.currentDatasetUnpublishScenario.id;
      }
      return null;
    },

    canLoadSource() {
      return (
        !this.newResource ||
        (
          this.newResource &&
          !(
            this.newResource.displayName ||
            // this.newResource.description ||
            // this.newResource.kind ||
            // this.newResource.lastUpdateDate ||
            this.newResource.format
          )
        )
      );
    },
  },

  async created() {
    this.loading = true;
    
    // Clean stored form
    this.SET_ORIGINAL_FORM({
      id: null,
      form: null
    });
    this.SET_CURRENT_FORM({
      id: null,
      form: null
    });
    this.SET_MAIN_RESOURCE(null);
    this.SET_MAIN_RESOURCE_DATA(null);
    this.SET_MAIN_RESOURCE_GEO_DATA(null);

    await this.GET_DATASET_DETAIL(this.$route.params.id);
    await this.GET_CURRENT_DATASET_ROLES(this.$route.params.id);
    await this.GET_CURRENT_DATASET_RESOURCES(this.$route.params.id);

    if (this.currentDataset && this.currentDataset.id) {
      if (this.currentDataset.usergroup) {
        this.UPDATE_CURRENT_DATASET_USERGROUP(this.currentDataset.usergroup);
      }
      // Toasts
      this.getLastDatasetTasks();
      this.SET_RELOAD_INTERVAL_ID(
        {
          name: 'dataset_tasks',
          interval: setInterval(() => {
            this.getLastDatasetTasks();
          },
          process.env.VUE_APP_RELOAD_INTERVAL)
        }
      );

      this.initForm();
    } else {
      this.loading = false;
    }

    if (this.organisationsList.length === 0) {
      this.GET_ORGANISATIONS_LIST({
        direction: null,
        field: null,
        page: 1
      });
    }
  },

  beforeRouteLeave(to, from, next) {
    this.SET_CURRENT_DATASET_RESOURCES(null);
    this.SET_CURRENT_DATASET({});
    this.CLEAR_RELOAD_INTERVAL_ID('dataset_tasks');
    this.CLEAR_RELOAD_INTERVAL_ID('resource_reloading');
    this.hiddenTasksIds.splice(0, this.hiddenTasksIds.length);
    next();
  },

  methods: {
    ...mapMutations([
      'SET_RELOAD_INTERVAL_ID',
      'CLEAR_RELOAD_INTERVAL_ID'
    ]),
    ...mapMutations('form', [
      'SET_CURRENT_FORM',
      'SET_ORIGINAL_FORM'
    ]),
    ...mapMutations('modal', [
      'OPEN_MODAL',
    ]),
    ...mapMutations('modal', [
      'SET_MODAL_STATE'
    ]),
    ...mapMutations('resources', [
      'SET_CURRENT_DATASET_RESOURCES',
      'SET_MAIN_RESOURCE',
      'SET_MAIN_RESOURCE_DATA',
      'SET_MAIN_RESOURCE_GEO_DATA',
      'SET_RESOURCES_FILTERS',
      'REMOVE_RESOURCES_FILTERS',
    ]),
    ...mapMutations('datasets', [
      'SET_DUPLICATED_DATASET',
      'SET_DUPLICATED_DATASET_ROLES',
      'SET_CURRENT_DATASET'
    ]),
    ...mapActions('datasets', [
      'GET_DATASET_STATIC_DATA',
      'GET_DATASET_DETAIL',
      'GET_CURRENT_DATASET_ROLES',
      'GET_DATASETS_LIST',
      'UPDATE_DATASET',
      'PATCH_DATASET',
      'DELETE_DATASET',
      'UPDATE_CURRENT_DATASET_USERGROUP',
    ]),
    ...mapActions('organisations', [
      'GET_ORGANISATIONS_LIST'
    ]),
    ...mapActions('resources', [
      'GET_CURRENT_DATASET_RESOURCES',
      'GET_MAIN_RESOURCE',
      'GET_ANNEX_RESOURCE',
      'START_CREATE_RESOURCE',
      'FINISH_CREATE_RESOURCE',
      'START_MODIFY_RESOURCE',
      'FINISH_MODIFY_RESOURCE',
      'START_UPDATE_RESOURCE',
      'FINISH_UPDATE_RESOURCE',
      'DELETE_RESOURCE',
    ]),
    ...mapActions('dashboard', [
      'GET_DASHBOARD_TASKS'
    ]),
    ...mapActions('usergroups', [
      'GET_CONTACTS_LIST'
    ]),

    setLoader(e) {
      this.loading = e;
    },

    setUsergroup(e) {
      this.form.usergroup = e;
    },

    async runDatasetPublishScenario(scenarioId) {
      try {
        this.loading = true;
        await datasetsAPI.buildDatasetIndex(this.currentDataset.id, scenarioId);
        this.getLastDatasetTasks();
        this.loading = false;
      } catch (err) {
        console.error(err);
        this.loading = false;
      }
    },

    async getLastDatasetTasks() {
      this.lastTasks = await dashboardAPI.getDatasetLastTasks(this.currentDataset.id);
      if (this.$refs && this.$refs['custom-toast']) {
        for (const toast of this.$refs['custom-toast']) {
          toast.hideToast();
        }
      }
      this.currentTasks.splice(0, this.currentTasks.length);
      this.currentTasks = [...this.lastTasks.filter(el => !this.hiddenTasksIds.includes(el.id))];
      this.currentTasks.sort((a,b) => {
        return new Date(a.stop_date) - new Date(b.stop_date);
      });
      this.currentTasks.forEach(e => e.visible = true);
    },

    hideToast(e) {
      const index = this.currentTasks.findIndex(el => el.id === e);
      const id = this.currentTasks.find(el => el.id === e).id;
      this.currentTasks.splice(index, 1);
      this.hiddenTasksIds.push(id);
    },

    initForm() {
      this.GET_DATASET_STATIC_DATA()
        .then(async () => {
          this.form.display_name = this.currentDataset.display_name;
          this.form.thumbnail = this.currentDataset.thumbnail;
          this.form.description = this.currentDataset.description;
          this.form.usergroup = this.currentDataset.usergroup;
          this.form.parent = this.currentDataset.parent;
          if (this.currentDataset.record_type && this.currentDataset.record_type.value) {
            this.form.recordType = {
              choice: this.currentDataset.record_type.value,
              label: this.currentDataset.record_type.label
            };
          }

          // Roles
          let order = 1;
          this.form.datasetRoles.splice(0);
          for (let role of this.currentDatasetRoles) {
            role.order = order;
            const contact = await datasetsAPI.getContact(role.contact.id);
            role.contact = contact;
            if (contact.usergroup && contact.usergroup.id) {
              role.contact.usergroup = JSON.parse(JSON.stringify(contact.usergroup.id));
            } else {
              role.contact.usergroup = null;
            }
            this.form.datasetRoles.push(role);
            order += 1;
          }

          this.form.categories =
            this.currentDataset.categories ? this.currentDataset.categories.map(el => el.display_name) : null;
          this.form.topics = this.currentDataset.topics.map(el => el.display_name);
          this.form.kinds = this.currentDataset.kinds.map(el => el.id);
          this.form.licence = this.currentDataset.license;
          this.form.copyright = this.currentDataset.copyright;
          this.form.granularity = this.currentDataset.granularity ? this.currentDataset.granularity : '';
          this.form.coverage =
            COVERAGE_OPTIONS.find(el => el.code === this.currentDataset.geographical_coverage) ?
              COVERAGE_OPTIONS.find(el => el.code === this.currentDataset.geographical_coverage) : '';
          this.form.keywords = this.currentDataset.tags;
          this.form.recommendation = this.currentDataset.recommendation;
          this.form.restriction = this.currentDataset.restriction ?
            this.datasetsRestrictions.find(el => el === this.currentDataset.restriction) ?
              this.datasetsRestrictions.find(el => el === this.currentDataset.restriction) :
              this.currentDataset.restriction :
            null;
          this.form.publicationDate = this.currentDataset.publication_date ? this.currentDataset.publication_date.slice(0, 10) : '';
          this.form.lastUpdateDate = this.currentDataset.last_update_date ? this.currentDataset.last_update_date.slice(0, 10) : '';
          this.form.creationDate = this.currentDataset.creation_date ? this.currentDataset.creation_date : null;
          this.form.revisionDate =
            this.currentDataset.last_revision_date ? this.currentDataset.last_revision_date : null;
          this.form.frequence =
            this.currentDataset.update_frequency ?
              this.datasetsUpdateFrequencies.find(el => el.value === this.currentDataset.update_frequency) :
              null,
          this.form.year = this.currentDataset.year;
          this.form.lineage = this.currentDataset.lineage;

          // extras
          if (this.currentDatasetExtras) {
            for (const extra of this.currentDatasetExtras) {
              if (extra.value) {
                this.form[extra.key] = extra.value;
              }
            }
          }

          this.SET_ORIGINAL_FORM({
            id: 'datasetDetail',
            form: this.form
          });

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    setForm(e) {
      this.form = e;
      this.SET_CURRENT_FORM({
        id: 'datasetDetail',
        form: e
      });
    },
    setThumbnail(e) {
      this.form.thumbnail = e;
    },

    async removeContact(index) {
      const removeContactFromDataset = async () => {
        try {
          this.loading = true;
          const roleId = this.form.datasetRoles[index].id;
          await datasetsAPI.removeDatasetRole(this.currentDataset.id, roleId);
          if (this.buildDatasetIndexScenarioId) {
            await datasetsAPI.buildDatasetIndex(this.currentDataset.id, this.buildDatasetIndexScenarioId);
          }
          this.form.datasetRoles.splice(index, 1);
          this.form.datasetRoles.forEach((el, i) => el.order = i + 1);
          await this.GET_CONTACTS_LIST({});
          await this.GET_CURRENT_DATASET_ROLES(this.currentDataset.id);
          this.$refs.datasetForm.checkIfMainContactExist();
          this.loading = false;
        } catch (err) {
          console.error(err);
          this.loading = false;
        }
      };

      this.OPEN_MODAL({
        modal: 'confirmation',
        open: true,
        title: this.$t('modals.removeContactFromDataset.title'),
        content: this.$t('modals.removeContactFromDataset.content'),
        trigger: removeContactFromDataset
      });
    },

    async afterResourceChange() {
      try {
        this.loading = true;
        this.isAddingAnnexe = false;
        this.newResource = null;
        if (this.buildDatasetIndexScenarioId) {
          await datasetsAPI.buildDatasetIndex(this.$route.params.id, this.buildDatasetIndexScenarioId);
        }
        await this.GET_CURRENT_DATASET_RESOURCES(this.$route.params.id);
        this.loading = false;
      } catch (err) {
        console.error(err);
        this.loading = false;
      }
    },

    setDatasetRecordType(e) {
      this.form.recordType = e;
    },

    duplicateDataset() {
      this.loading = true;
      this.SET_DUPLICATED_DATASET(this.currentDataset);
      this.SET_DUPLICATED_DATASET_ROLES(this.currentDatasetRoles);
      this.$router.push({
        name: 'AddDataset'
      })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async saveDataset() {
      this.$root.$emit('updateResourceInfos');

      const data = {
        ...this.currentDataset,
        ...this.form.display_name && { display_name: this.form.display_name },
        ...this.form.description && { description: this.form.description },
        ...this.form.usergroup.id && { usergroup: this.form.usergroup.id },
        record_type_value: this.form.recordType ? this.form.recordType.choice : null,
        // eslint-disable-next-line no-unused-vars
        contact_roles: this.form.datasetRoles.map(({ order, ...el }) => {
          el.contact.usergroup_id = el.contact.usergroup;
          delete el.contact.usergroup;
          return el;
        }),
        categories: this.datasetsCategories.filter(el => this.form.categories.includes(el.display_name)),
        topics: this.datasetsTopics.filter(el => this.form.topics.includes(el.display_name)),
        kinds: this.datasetsKinds.filter(el => this.form.kinds.includes(el.id)),
        tags: this.form.keywords,
        recommendation: this.form.recommendation ? this.form.recommendation : null,
        restriction: this.form.restriction,
        license: this.form.licence,
        copyright: this.form.copyright ? this.form.copyright : null,
        granularity: this.form.granularity ? this.form.granularity : null,
        geographical_coverage: this.form.coverage ? this.form.coverage.code : null,
        publication_date: this.form.publicationDate ?
          new Date(this.form.publicationDate).toISOString().split('T')[0] : null,
        last_update_date: this.form.lastUpdateDate ?
          new Date(this.form.lastUpdateDate).toISOString().split('T')[0] : null,
        year: this.form.year,
        lineage: this.form.lineage ? this.form.lineage : null,
        update_frequency: this.form.frequence ? this.form.frequence.value : null,
      };

      // Add extras
      if (
        this.$config.forms.dataset.extraFields &&
        this.$config.forms.dataset.extraFields.length
      ) {
        const extras = [];
        for (const extraField of this.$config.forms.dataset.extraFields) {
          for (const field of extraField.fields) {
            extras.push({
              key: field.key,
              value: this.form[field.key] && this.form[field.key].length ? this.form[field.key] : null
            });
          }
        }
        data.extras = extras;
      }

      // publish value can't be null, force true if null
      if (this.currentDataset.publish === null) {
        data.publish = true;
      }
      this.loading = true;

      if (this.form.thumbnail instanceof FormData) {
        await datasetsAPI.setDatasetThumbnail(this.currentDataset.id, this.form.thumbnail);
      } else if (this.currentDataset.thumbnail && this.form.thumbnail === null) {
        await datasetsAPI.removeDatasetThumbnail(this.currentDataset.id);
      }

      // eslint-disable-next-line no-unused-vars
      const contactRoles = this.form.datasetRoles.map(({ order, ...el }) => { return el; });

      // Create new contacts
      for (let [i, role] of contactRoles.entries()) {
        if (!role.contact.id) {
          try {
            const newContact = await datasetsAPI.createContact(role.contact);
            contactRoles[i].contact.id = newContact.id;
          } catch (err) {
            console.error(err);
          }
        }
      }

      // Check for changed contacts
      for (let role of contactRoles) {
        const originalContact = this.currentDatasetRoles.find(el => el.contact.id === role.contact.id);
        if (originalContact) {
          if (!this.lookForChangesInContact(originalContact.contact, role.contact)) {
            try {
              await datasetsAPI.updateContact(role.contact.id, role.contact);
            } catch (err) {
              ErrorService.onError(err, undefined, `${this.$t('messages.error.contact.update')} ${role.contact.name}`);
            }
          }
        }
      }

      // Check for changed roles
      for (const role of contactRoles) {
        const originalContact = this.currentDatasetRoles.find(el => el.contact.id === role.contact.id);
        if (originalContact && ((originalContact.role !== role.role) || (originalContact.main !== role.main))) {
          let force = false;
          if (originalContact.main !== role.main) {
            force = true;
          }
          await datasetsAPI.updateDatasetRole(
            this.currentDataset.id,
            {
              role: role.role,
              contact_id: role.contact.id,
              main: role.main
            },
            role.id,
            force
          );
        }
      }

      // Check for created roles
      for (const role of contactRoles) {
        if (!role.id || (role.id && !(this.currentDatasetRoles.map(el => el.id).includes(role.id)))) {
          try {
            await datasetsAPI.createDatasetRole(this.currentDataset.id, {
              role: role.role,
              contact_id: role.contact.id,
              main: role.main
            });
          } catch (err) {
            console.error(err);
          }
        }
      }

      // Check for removed roles
      for (const role of this.currentDatasetRoles) {
        if (!(contactRoles.map(el => el.id).includes(role.id))) {
          await datasetsAPI.removeDatasetRole(this.currentDataset.id, role.id);
        }
      }

      this.UPDATE_DATASET({
        id: this.currentDataset.id,
        data: data
      })
        .then(async () => {
          if (this.currentDataset && this.currentDataset.id) {
            await this.GET_DATASET_DETAIL(this.currentDataset.id);
            await this.GET_CURRENT_DATASET_ROLES(this.currentDataset.id);
            if (this.currentDataset && this.currentDataset.id) {
              this.initForm();
              // await datasetsAPI.buildDatasetIndex(this.currentDataset.id, this.buildDatasetIndexScenarioId);
              this.getLastDatasetTasks();
            }
          }
          this.GET_DATASETS_LIST({
            direction: null,
            field: null
          });
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
        });
    },

    setResource(e) {
      this.newResource = e;
    },

    startModifyResource(e) {
      this.modifyResource = e;
      let datastoreType = null;
      // let geoserverLayerType = null;
      if (this.modifyResource.resourceType === 'datastore') {
        datastoreType = this.modifyResource.data.format;
      }
      // if (this.modifyResource.resourceType === 'geoserver') {
      //   geoserverLayerType = this.modifyResource.data.layerType;
      // }

      if (e.type === 'resource') {
        this.loadingResource = true;
        this.disablingResource = true;
      } else if (e.type === 'annexe') {
        this.annexeLoadingId = e.resourceId;
      } else {
        this.loading = true;
      }
      this.START_MODIFY_RESOURCE({
        typeResource: e.resourceType,
        data: e.data,
        resourceId: e.resourceId,
        datastoreType: datastoreType,
      })
        .then(async () => {
          if (this.currentResourceScenarios.update && this.currentResourceScenarios.update.length) {
            this.possibleScenarios = this.currentResourceScenarios.update;
            this.selectingScenarioContext = 'modify';
            if (this.possibleScenarios.length > 1) {
              this.selectingScenario = true;
            } else {
              this.finishModifyResource(this.possibleScenarios[0].id);
            }
          } else {
            ErrorService.onError(null, this.$t('messages.error.scenario.update'));
            await this.GET_CURRENT_DATASET_RESOURCES(this.$route.params.id);
            this.loading = false;
            this.loadingResource = false;
            this.disablingResource = false;
            this.annexeLoadingId = null;
          }
        })
        .catch(async (err) => {
          console.error(err);
          await this.GET_CURRENT_DATASET_RESOURCES(this.$route.params.id);
          this.loading = false;
          this.loadingResource = false;
          this.disablingResource = false;
          this.annexeLoadingId = null;
        });
    },

    finishModifyResource(scenarioId) {
      this.selectingScenario = false;
      const finishResourceUpdateData = {
        kwargs: {
          ...this.modifyResource.geographicLayer && this.modifyResource.geographicLayer.geographiclayerDisplayName && {
            geographiclayer__display_name: this.modifyResource.geographicLayer.geographiclayerDisplayName
          },
          ...this.modifyResource.geographicLayer && this.modifyResource.geographicLayer.columnX && {
            column_x: this.modifyResource.geographicLayer.columnX
          },
          ...this.modifyResource.geographicLayer && this.modifyResource.geographicLayer.columnY && {
            column_y: this.modifyResource.geographicLayer.columnY
          },
          ...this.modifyResource.geographicLayer && this.modifyResource.geographicLayer.crs && {
            crs: this.modifyResource.geographicLayer.crs
          },
          usergroup_id: this.currentDataset.usergroup.id,
          dataset_id: this.currentDataset.id,
          target: {
            app_label: 'onegeo_dataset',
            model: 'dataset',
            pk: this.currentDataset.id
          },
          type: this.modifyResource.type === 'resource' ? 1 : 2
        }
      };

      let datastoreType = null;
      if (this.modifyResource.resourceType === 'datastore') {
        datastoreType = this.modifyResource.data.format;
      }
      this.FINISH_MODIFY_RESOURCE({
        typeResource: this.modifyResource.resourceType,
        scenarioId: scenarioId,
        data: finishResourceUpdateData,
        ...this.modifyResource.resourceType === 'ftp' && { resourceData: this.modifyResource.data },
        ...this.modifyResource.resourceType === 'href' && { resourceData: this.modifyResource.data },
        ...this.modifyResource.resourceType === 'file-download' && { resourceData: this.modifyResource.data },
        ...this.modifyResource.resourceType === 'datastore' && {
          datastoreType: this.modifyResource.data.format,
          datastoreId: this.modifyResource.data.id
        },
        datasetId: this.currentDataset.id,
        resourceId: this.modifyResource.resourceId,
        datastoreType: datastoreType,
        // async: true
      })
        .then(async () => {
          this.loadingResource = true;
          await this.GET_CURRENT_DATASET_RESOURCES(this.$route.params.id);
          if (this.modifyResource.type === 'resource') {
            await this.GET_MAIN_RESOURCE({
              id: this.currentDatasetMainResource.resource.id,
              resourceToDatasetId: this.currentDatasetMainResource.id
            });
          } else if (this.modifyResource.type === 'annexe') {
            this.$refs.annexesComp.getAnnexesData();
          }
          this.modifyResource = null;
          this.loadingResource = false;
          this.annexeLoadingId = null;
          this.loading = false;
          this.afterCreationRequests();
        })
        .catch((err) => {
          console.error(err);
          this.modifyResource = null;
          this.loadingResource = false;
          this.annexeLoadingId = null;
          this.loading = false;
        });
    },

    startUpdateResource(e) {
      this.updateResource = e;
      let datastoreType = null;
      let geoserverLayerType = null;
      if (this.updateResource.resourceType === 'datastore') {
        datastoreType = this.updateResource.data.format;
      }
      if (this.updateResource.resourceType === 'geoserver') {
        geoserverLayerType = this.updateResource.data.layerType;
      }

      if (e.type === 'resource') {
        this.loadingResource = true;
        this.disablingResource = true;
      } else if (e.type === 'annexe') {
        this.annexeLoadingId = e.resourceId;
      } else {
        this.loading = true;
      }

      this.START_UPDATE_RESOURCE({
        typeResource: e.resourceType,
        data: e.data,
        datastoreType: datastoreType,
        layerType: geoserverLayerType,
      })
        .then(() => {
          if (this.currentResourceScenarios.update && this.currentResourceScenarios.update.length) {
            this.possibleScenarios = this.currentResourceScenarios.update;
            this.selectingScenarioContext = 'update';
            if (this.possibleScenarios.length > 1) {
              this.selectingScenario = true;
            } else {
              this.finishUpdateResource(this.possibleScenarios[0].id);
            }
          } else {
            ErrorService.onError(null, this.$t('messages.error.scenario.update'));
            this.loading = false;
            this.loadingResource = false;
            this.annexeLoadingId = null;
          }
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
          this.loadingResource = false;
          this.annexeLoadingId = null;
        });
    },

    finishUpdateResource(action) {
      this.selectingScenario = false;
      
      this.FINISH_UPDATE_RESOURCE({
        type: this.updateResource.type,
        typeResource: this.updateResource.resourceType,
        action: action,
        ...this.updateResource.resourceType === 'datastore' && {
          datastoreType: this.updateResource.data.format,
          datastoreId: this.updateResource.data.id
        },
        kwargs: {
          usergroup_id: this.currentDataset.usergroup.id,
          dataset_id: this.currentDataset.id,
          target: {
            app_label: 'onegeo_dataset',
            model: 'dataset',
            pk: this.currentDataset.id
          }
        }
      })
        .then(async () => {
          await this.GET_CURRENT_DATASET_RESOURCES(this.$route.params.id);
          this.updateResource = null;
          this.loadingResource = false;
          this.annexeLoadingId = null;
          this.loading = false;
          this.afterCreationRequests();
        })
        .catch((err) => {
          console.error(err);
          this.updateResource = null;
          this.loadingResource = false;
          this.annexeLoadingId = null;
          this.loading = false;
        });
    },

    startCreateResource() {
      try {
        this.loadingResource = true;
        this.disablingResource = true;

        let resourceCreationData;
        // let scheduler;
        let datastoreType = null;

        const postResourceData = {
          display_name: this.newResource.displayName,
          description: this.newResource.description,
          ...this.newResource.kind && this.newResource.kind.id && {
            kind_id: this.newResource.kind.id
          },
          last_update_date: this.newResource.lastUpdateDate,
          usergroup_id: this.currentDataset.usergroup.id,
        };

        if (this.newResource.type === 'datastore') {
          const { format, ...datastoreData } = this.newResource;
          resourceCreationData = {
            namespace: datastoreData.namespace,
            schema_name: datastoreData.schema_name,
            table_name: datastoreData.table_name,
          };
          datastoreType = format;
        } else {
          const { format, ...resourceForm } = this.newResource;
          resourceCreationData = {
            ...resourceForm,
            organisation: this.currentDataset.usergroup.id,
            ...this.newResource.type === 'file-upload' && {
              dataformat_id: format.id,
            },
            ...this.newResource.type === 'ftp' && {
              dataformat_id: format.id,
              href: this.newResource.selectedFtpFiles.map(el => el.value),
            },
            ...this.newResource.type === 'href' && {
              dataformat_id: format.id,
              href: this.newResource.href,
            },
            ...this.newResource.type === 'file-download' && {
              dataformat_id: format.id,
              href: this.newResource.href,
            },
            ...this.newResource.type === 'geoserver' && {
              type: this.newResource.layerType
            }
          };
        }

        // if (['ftp', 'href', 'file-download'].includes(this.newResource.type)) {
        //   scheduler = this.newResource.scheduler;
        // }
        this.START_CREATE_RESOURCE({
          slug: this.newResource.type,
          postResourceData: postResourceData,
          resourceCreationData: resourceCreationData,
          datasetId: this.currentDataset.id,
          type: 1,
          ...this.newResource.type === 'datastore' && {
            datastoreType: datastoreType
          },
          ...this.newResource.type === 'geoserver' && {
            layerType: this.newResource.layerType
          },
        })
          .then(() => {
            if (this.currentResourceScenarios.create && this.currentResourceScenarios.create.length) {
              this.possibleScenarios = this.currentResourceScenarios.create;
              this.selectingScenarioContext = 'create';
              if (this.possibleScenarios.length > 1) {
                this.selectingScenario = true;
              } else {
                this.finishCreateResource(this.possibleScenarios[0].id,/* scheduler*/);
              }
            } else {
              ErrorService.onError(null, this.$t('messages.error.scenario.create'));
              this.loadingResource = false;
            }
          })
          .catch((err) => {
            console.error(err);
            this.loadingResource = false;
          });
      } catch (err) {
        console.error(err);
        this.loadingResource = false;
      }
    },

    finishCreateResource(scenarioId, /*scheduler*/) {
      this.selectingScenario = false;
      const finishResourceCreationData = {
        kwargs: {
          // resource__display_name: this.newResource.displayName,
          ...this.newResource.geographiclayerDisplayName && {
            geographiclayer__display_name: this.newResource.geographiclayerDisplayName
          },
          ...this.newResource.columnX && {
            column_x: this.newResource.columnX
          },
          ...this.newResource.columnY && {
            column_y: this.newResource.columnY
          },
          ...this.newResource.crs && {
            crs: this.newResource.crs
          },
          // resource__description: this.newResource.description,
          // resource__kind_id: this.newResource.kind && this.newResource.kind.id ? this.newResource.kind.id : null,
          // resource__last_update_date: this.newResource.lastUpdateDate,
          // usergroup_id: this.currentDataset.usergroup.id,
          dataset_id: this.currentDataset.id,
          resource_id: this.lastCreatedResource.id,
          target: {
            app_label: 'onegeo_dataset',
            model: 'dataset',
            pk: this.currentDataset.id
          }
        }
      };

      this.FINISH_CREATE_RESOURCE({
        typeResource: this.newResource.type,
        scenarioId: scenarioId,
        data: finishResourceCreationData,
        // scheduler: scheduler,
        ...this.newResource.type === 'datastore' && { datastoreType: this.newResource.format },
        ...this.newResource.type === 'geoserver' && { layerType: this.newResource.layerType },
      })
        .then(() => {
          setTimeout(async () => {
            await this.GET_CURRENT_DATASET_RESOURCES(this.$route.params.id);
            this.loadingResource = false;
            this.annexeLoadingId = null;
            this.loading = false;
            this.afterCreationRequests();
          }, 2000);
        })
        .catch((err) => {
          console.error(err);
          this.loadingResource = false;
        });
    },

    // Fired after each resource creation
    afterCreationRequests() {
      this.SET_CURRENT_FORM({
        id: null,
        form: null
      });
      this.GET_DASHBOARD_TASKS({
        direction: null,
        field: null
      });
      this.loadingResource = false;
    },

    lookForChangesInContact(original, current) {
      return (
        original.name === current.name &&
        original.email === current.email &&
        original.position === current.position &&
        original.phone_number === current.phone_number &&
        original.usergroup === current.usergroup_id
      );
    },

    openDatasetInPortal(datasetSlug) {
      return `${process.env.VUE_APP_PORTAIL_ROUTE}jeux-de-donnees/${datasetSlug}`;
    },
  }
};
</script>
