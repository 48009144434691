<template>
  <div class="main_resource">
    <b-overlay
      :show="isLoading"
      :variant="'white'"
      :opacity="0.7"
      :blur="'2px'"
      rounded="sm"
      no-wrap
      style="z-index: 1050;"
    />
    <div v-if="isCurrentDatasetMainResourcePending || isCurrentDatasetMainResourceFailed">
      <ResourceProcessingAlert
        v-for="process of currentDatasetMainResourceProcessings"
        :key="process.id"
        :process="process"
        :resource-id="currentDatasetMainResource.resource.id"
        :task-id="process.task_id"
      />
    </div>
    <div v-if="mainResource">
      <ResourceEditor
        :key="`main-resource-editor-${mainResource.id}`"
        :meta-resource="mainResource"
        :resource="mainResourceData"
        :disabled="isCurrentDatasetMainResourcePending"
        v-on="$listeners"
        @get="getMainResource"
        @delete="isDeleteResourceModalOpen = true"
      />
      <div
        :class="{
          disabled: isCurrentDatasetMainResourcePending
        }"
        class="resource__delete"
      >
        <div />
        <b-button
          variant="danger"
          @click="isDeleteResourceModalOpen = true"
        >
          {{ $t(`buttons.delete`) }}
        </b-button>
      </div>
    </div>
    <div
      v-else-if="!mainResource && (
        !currentDatasetMainResource || (currentDatasetMainResource && !isCurrentDatasetMainResourcePending)
      )"
    >
      <p class="infos">
        {{ $t('datasetDetail.mainResource.empty') }}
      </p>
      <ResourceCreator
        :required="false"
        v-on="$listeners"
      />
    </div>
    <DeleteResourceModal
      :open="isDeleteResourceModalOpen"
      :resource="mainResourceData"
      @hide="isDeleteResourceModalOpen = false"
      @delete="deleteResource"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import DeleteResourceModal from '@/components/Resource/modals/DeleteResourceModal.vue';
import ResourceCreator from '@/components/Resource/ResourceCreator.vue';
import ResourceEditor from '@/components/Resource/ResourceEditor.vue';
import ResourceProcessingAlert from '@/components/Resource/ResourceProcessingAlert.vue';

export default {
  name: 'MainResource',

  components: {
    DeleteResourceModal,
    ResourceCreator,
    ResourceEditor,
    ResourceProcessingAlert
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    },
  },
  
  data() {
    return {
      isLoading: false,
      isDeleteResourceModalOpen: false
    };
  },

  computed: {
    ...mapState('datasets', [
      'currentDataset',
    ]),
    ...mapState('resources', [
      'mainResource',
      'mainResourceData',
      'mainResourceDatasetRelation'
    ]),
    ...mapGetters('resources', [
      'currentDatasetMainResource',
      'isCurrentDatasetMainResourcePending',
      'isCurrentDatasetMainResourceFailed',
      'currentDatasetMainResourceProcessings',
      'mainResourceRoot',
    ]),
  },

  watch: {
    loading(newValue) {
      this.isLoading = newValue;
    },
    currentDatasetMainResource: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue && newValue.id && JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.getMainResource();
        }
      }
    },
  },

  async created() {
    if (this.currentDatasetMainResource && this.currentDatasetMainResource.id) {
      await this.getMainResource();
    } else if (!this.currentDatasetMainResource) {
      this.SET_MAIN_RESOURCE(null);
    }
  },

  mounted() {
    // Watch modal closing event
    this.$root.$on('bv::modal::hide', () => {
      this.isDeleteResourceModalOpen = false;
    });
  },

  methods: {
    ...mapMutations('resources', [
      'SET_MAIN_RESOURCE'
    ]),
    ...mapActions('resources', [
      'GET_MAIN_RESOURCE',
      'DELETE_RESOURCE_FROM_DATASET',
      'DELETE_RESOURCE'
    ]),

    async getMainResource() {
      try {
        this.isLoading = true;
        await this.GET_MAIN_RESOURCE({
          id: this.currentDatasetMainResource.resource.id,
          resourceToDatasetId: this.currentDatasetMainResource.id
        });
        this.isLoading = false;
      } catch(err) {
        console.error(err);
        this.isLoading = false;
      }
    },

    deleteResource() {
      this.isLoading = true;
      this.DELETE_RESOURCE_FROM_DATASET({
        resourceToDatasetId: this.currentDatasetMainResource.id,
        resource: this.currentDatasetMainResource.resource
      })
        .then(() => {
          this.DELETE_RESOURCE(this.currentDatasetMainResource.resource)
            .then(() => {
              this.SET_MAIN_RESOURCE(null);
              this.isLoading = false;
            })
            .catch((err) => {
              console.error(err);
              this.isLoading = false;
            });
          this.$emit('delete');
        })
        .catch((err) => {
          console.error(err);
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.alert-resource {
  margin: 1rem -1px 1rem -1px !important;
}
</style>
