<template>
  <div
    :key="`resource_creator-${componentKey}`"
    class="resource_creator"
  >
    <b-card
      ref="resourceTabs"
      no-body
    >
      <b-tabs
        card
        pills
        fill
      >
        <b-tab
          v-for="tab of resourceTabs"
          :key="`${isMain ? 'resource' : 'annexe'}-${tab.resource}-tab`"
          :active="activeTab.name === tab.name"
          @click="setActiveComponent(tab)"
        >
          <template #title>
            <i :class="`bi bi-${tab.icon}`" />
            &nbsp; {{ tab.title }}
          </template>
          <b-card-text>
            <component
              :is="tab.component"
              :required="activeTab.name === tab.name && isRequired"
              :is-main="isMain"
              @set="setForm"
              @geo="setIsGeospatial"
              @xy="setIsXY"
            />
          </b-card-text>
        </b-tab>
      </b-tabs>
      <div class="resource_form">
        <ResourceMetaData
          :key="`${isMain ? 'resource' : 'annexe'}-metadata`"
          :type="isMain ? 'resource' : 'annexe'"
          :required="isRequired"
          :form-config="$config.forms.resource[activeTab.resource]"
          @set="setForm"
        />
      </div>

      <div
        v-if="isMain && isGeospatial"
        class="resource_form"
        style="border-top: 1px solid rgba(0, 0, 0, 0.125); padding-top: 0.5rem;"
      >
        <ResourceGeographicalLayer
          :key="`${isMain ? 'resource' : 'annexe'}-geo`"
          :required="isRequired"
          :form-config="$config.forms.resource[activeTab.resource]"
          :is-x-y="isXY"
          @set="setForm"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import DatastoreCreator from '@/components/Resource/creators/DatastoreCreator.vue';
import FileDownloadCreator from '@/components/Resource/creators/FileDownloadCreator.vue';
import FileUploadCreator from '@/components/Resource/creators/FileUploadCreator.vue';
import FTPCreator from '@/components/Resource/creators/FTPCreator.vue';
import GeoserverCreator from '@/components/Resource/creators/GeoserverCreator.vue';
import HrefCreator from '@/components/Resource/creators/HrefCreator.vue';

import ResourceGeographicalLayer from '@/components/Resource/forms/ResourceGeographicalLayer';
import ResourceMetaData from '@/components/Resource/forms/ResourceMetaData';

export default {
  name: 'ResourceCreator',

  components: {
    ResourceGeographicalLayer,
    ResourceMetaData
  },

  props: {
    isMain: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      componentKey: 1,
      activeTab: null,
      isRequired: true,
      form: {
        displayName: null,
        geographiclayerDisplayName: null,
        // geographiclayerCodename: null,
        columnX: null,
        columnY: null,
        crs: null,
        description: null,
        kind: null,
        lastUpdateDate: null
      },
      sortedKinds: [],
      isGeospatial: false,
      isXY: false
    };
  },

  computed: {
    ...mapState('user-login', [
      'userData'
    ]),
    ...mapState('resources', [
      'resourceKinds'
    ]),

    resourceTabs() {
      return [
        ...this.isMain && this.isResourceTypeAvailable('datastore') ? [{
          resource: 'datastore',
          name: 'datastoreResourceTab',
          icon: 'database-fill',
          title: this.$t('resource.datastore.title'),
          component: DatastoreCreator
        }] : [],
        ...this.isResourceTypeAvailable('file-upload') ? [{
          resource: 'fileUpload',
          name: 'fileResourceTab',
          icon: 'file-earmark-arrow-up-fill',
          title: this.$t('resource.fileUpload.title'),
          component: FileUploadCreator

        }] : [],
        ...this.isResourceTypeAvailable('ftp') ? [{
          resource: 'ftp',
          name: 'ftpResourceTab',
          icon: 'hdd-fill',
          title: this.$t('resource.ftp.title'),
          component: FTPCreator
        }] : [],
        ...this.isResourceTypeAvailable('href') ? [{
          resource: 'href',
          name: 'urlResourceTab',
          icon: 'link',
          title: this.$t('resource.url.title'),
          component: HrefCreator
        }] : [],
        ...this.isResourceTypeAvailable('file-download') ? [{
          resource: 'fileDownload',
          name: 'fileDownloadResourceTab',
          icon: 'file-earmark-arrow-down-fill',
          title: this.$t('resource.fileDownload.title'),
          component: FileDownloadCreator
        }] : [],
        ...this.isMain && this.isResourceTypeAvailable('geoserver') ? [{
          resource: 'geoserver',
          name: 'geoserverResourceTab',
          icon: 'layers-fill',
          title: this.$t('resource.geoserver.title'),
          component: GeoserverCreator
        }] : []
      ];
    }
  },

  watch: {
    required(newValue) {
      this.isRequired = newValue;
    },
    isRequired(newValue) {
      this.$emit('required', newValue);
    },
    form: {
      deep: true,
      handler(newValue) {
        // if (newValue.display_name || newValue.description || newValue.kind) {
        //   this.isRequired = true;
        // } else {
        //   this.isRequired = false;
        // }
        this.$emit('set', {
          ...this.form,
          ...newValue
        });
      }
    }
  },

  created() {
    this.isRequired = this.required;
    if (this.resourceKinds.length === 0) {
      this.GET_RESOURCE_KINDS()
        .then(() => {
          this.sortedKinds = this.resourceKinds.sort((a, b) => a.display_name.localeCompare(b.display_name));
        });
    } else {
      this.sortedKinds = this.resourceKinds.sort((a, b) => a.display_name.localeCompare(b.display_name));
    }
    this.activeTab = this.resourceTabs[0];
  },

  methods: {
    ...mapActions('resources', [
      'GET_RESOURCE_KINDS'
    ]),

    isResourceTypeAvailable(resourceType) {
      if (
        this.$config.resourceTypes.includes(resourceType) && (
          (this.$config.forms.resource[resourceType].superuserOnly && this.userData.is_superuser) ||
            !this.$config.forms.resource[resourceType].superuserOnly
        )
      ) {
        return true;
      }
      return false;
    },

    setActiveComponent(tab) {
      this.activeTab = tab;
      this.isRequired = false;
      this.setForm({
        required: this.isRequired,
        form: {
          displayName: null,
          format: null,
          geographiclayerDisplayName: null,
          // geographiclayerCodename: null,
          columnX: null,
          columnY: null,
          crs: null,
          description: null,
          kind: null,
          lastUpdateDate: null
        }
      });
      this.componentKey += 1;
    },

    setRequired(e) {       // Keep it, called from parent
      this.isRequired = e;
    },

    setIsGeospatial(e) {
      this.isGeospatial = e;
    },
    setIsXY(e) {
      if (e === true) {
        this.isGeospatial = true;
      }
      this.isXY = e;
    },

    setForm(e) {
      if (e.required !== undefined) {
        this.isRequired = e.required;
      }
      if ('displayName' in e) {
        this.form.displayName = e.displayName;
      }
      if (e.form) {
        this.form = {
          ...this.form,
          ...e.form
        };
        this.$emit('set', this.form);
      }
    },
  }

};
</script>

<style scoped lang="less">
.card-body {
  padding: 1rem 0 !important;
}
</style>

