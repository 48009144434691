<template>
  <div class="card-header">
    <div class="header-top">
      <div class="resource_icon">
        <i class="bi bi-database-fill" />
      </div>
      <div class="resource_format">
        {{ resource['@label'] }}
      </div>
      <div class="resource_name">
        {{ `${resource.schema_name}.${resource.table_name}` }}
      </div>
    </div>
    <div class="header-bottom">
      <slot name="publication" />
      <div
        class="form-group resource-form-footer"
      >
        <b-button
          variant="outline-secondary"
          @click="updateDatastore"
        >
          {{ $t('buttons.update') }}
        </b-button>
        <b-button
          variant="outline-secondary"
          @click="isUpdateDatastoreResourceModalOpen = true"
        >
          {{ $t('buttons.edit') }}
        </b-button>
      </div>
    </div>
    <UpdateDatastoreModal
      :open="isUpdateDatastoreResourceModalOpen"
      :format="resource['@class']"
      @hide="isUpdateDatastoreResourceModalOpen = false"
      @update="modifyDatastore"
    />
  </div>
</template>

<script>
import UpdateDatastoreModal from '@/components/Resource/modals/UpdateDatastoreModal.vue';

export default {
  name: 'DatastoreEditor',

  components: {
    UpdateDatastoreModal
  },

  props: {
    isMain: {
      type: Boolean,
      default: true
    },
    resource: {
      type: Object,
      default: () => {}
    },
    metaResource: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      // modals
      isUpdateDatastoreResourceModalOpen: false,
    };
  },

  mounted() {
    // Watch modal closing event
    this.$root.$on('bv::modal::hide', () => {
      this.isUpdateDatastoreResourceModalOpen = false;
    });
  },

  methods: {
    async updateDatastore() {
      const formatOptions = [
        { value: 'postgres', label: 'PostgreSQL', type: 'datapusher' },
        { value: 'postgis', label: 'PostGIS', type: 'geospatial' }
      ];
      this.$emit('update', {
        data: {
          id: this.resource.id,
          format: formatOptions.find(el => el.value === this.resource['@class'])
        },
        type: this.isMain ? 'resource' : 'annexe',
        resourceType: 'datastore',
        resourceId: this.metaResource.id,
      });
    },

    modifyDatastore(e) {
      this.$emit('modify', {
        data: {
          id: this.resource.id,
          ...e.form
        },
        type: this.isMain ? 'resource' : 'annexe',
        resourceType: 'datastore',
        resourceId: this.metaResource.id,
      });
      this.isUpdateDatastoreResourceModalOpen = false;
    },

    deleteResource() {
      this.$emit('delete');
    },
  }

};
</script>