<template>
  <div
    :id="`accordion-${id}`"
    class="accordion accordion-flush"
  >
    <div class="accordion-item">
      <h2 class="accordion-header">
        <button
          class="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="`#flush-collapse-1-${id}`"
          aria-expanded="true"
          aria-controls="flush-collapseOne"
        >
          {{ capitalizeFirstLetter($tc('words.generalInformations')) }}
        </button>
      </h2>
      <div
        :id="`flush-collapse-1-${id}`"
        class="accordion-collapse collapse show"
      >
        <div class="accordion-body">
          <ResourceMetaData
            :is-main="isMain"
            :form-config="formConfig"
            :meta-data="metaData"
            @set="setMetaData"
          />
        </div>
      </div>
    </div>
    <div
      v-if="isMain && mainResourceGeographicLayer"
      class="accordion-item"
    >
      <h2 class="accordion-header">
        <button
          class="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="`#flush-collapse-2-${id}`"
          aria-expanded="false"
          aria-controls="flush-collapseTwo"
        >
          {{ capitalizeFirstLetter($tc('words.geographicalLayer')) }}
        </button>
      </h2>
      <div
        :id="`flush-collapse-2-${id}`"
        class="accordion-collapse collapse"
      >
        <div class="accordion-body">
          <ResourceGeographicalLayer
            :key="`resource-geographical-layer-form-${mainResourceGeographicLayer.codename}`"
            :form-config="formConfig"
            :geo-data="geoData"
            :codename-disabled="true"
            :is-x-y="isXY"
            @set="setGeoData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import resourcesAPI from '@/api/resourcesAPI.js';
import { ErrorService } from '@/services/error-service.js';

import ResourceGeographicalLayer from '@/components/Resource/forms/ResourceGeographicalLayer.vue';
import ResourceMetaData from '@/components/Resource/forms/ResourceMetaData.vue';

export default {
  name: 'ResourceAccordion',

  components: {
    ResourceGeographicalLayer,
    ResourceMetaData
  },

  props: {
    id: {
      type: Number,
      default: 1
    },
    isMain: {
      type: Boolean,
      default: true
    },
    formConfig: {
      type: Object,
      default: () => {}
    },
    metaResource: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      metaData: {
        displayName: null,
        description: null,
        kind: null,
        lastUpdateDate: null
      },
      geoData: {
        geographiclayerDisplayName: null,
        // geographiclayerCodename: null,
        columnX: null,
        columnY: null,
        crs: null
      }
    };
  },

  computed: {
    ...mapState('datasets', [
      'currentDataset'
    ]),
    ...mapState('resources', [
      'mainResource',
      'mainResourceGeoData',
      'resourceKinds'
    ]),
    ...mapGetters('resources', [
      'mainResourceGeographicLayer'
    ]),

    isXY() {
      return false;
    },

    isMetaDataChanged() {
      return this.metaData.displayName !== this.metaResource.display_name ||
        this.metaData.description !== this.metaResource.description ||
        this.metaData.lastUpdateDate !== this.metaResource.last_update_date ||
        (this.metaResource.kind && this.metaData.kind !== this.resourceKinds.find(el => el.id === this.metaResource.kind.id)) ||
        (!this.metaResource.kind && this.metaData.kind);
    },
    isGeoDataChanged() {
      return this.mainResourceGeoData &&
        this.geoData.geographiclayerDisplayName !== this.mainResourceGeoData.display_name;
    }
  },

  watch: {
    metaResource: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.initForms();
        }
      }
    },

    mainResourceGeographicLayer: {
      deep: true,
      async handler(newValue) {
        // refresh data
        if (newValue && newValue['@uri']) {
          const response = await resourcesAPI.getResourceData(newValue['@uri']);
          if (response) {
            this.SET_MAIN_RESOURCE_GEO_DATA(response);
            this.geoData.geographiclayerDisplayName = response.display_name;
            // this.geoData.geographiclayerCodename = response.codename;
          }
        }
      }
    }
  },

  created() {
    this.$root.$on('updateResourceInfos', () => {
      this.updateResourceInfos();
    });

    this.initForms();
  },

  methods: {
    ...mapMutations('resources', [
      'SET_MAIN_RESOURCE_GEO_DATA'
    ]),
    ...mapActions('resources', [
      'PATCH_RESOURCE'
    ]),

    async initForms() {
      try {
        if (this.metaResource) {
          this.metaData.displayName = this.metaResource.display_name;
          this.metaData.description = this.metaResource.description;
          if (this.metaResource.kind) {
            this.metaData.kind = this.resourceKinds.find(el => el.id === this.metaResource.kind.id);
          }
          this.metaData.lastUpdateDate = this.metaResource.last_update_date;
        }
        if (
          this.mainResourceGeographicLayer &&
            (!this.mainResourceGeoData ||
              this.mainResourceGeoData.id !== this.mainResourceGeographicLayer.id
            )
        ) {
          this.$emit('loading', true);
          const response = await resourcesAPI.getResourceData(this.mainResourceGeographicLayer['@uri']);
          if (response) {
            this.SET_MAIN_RESOURCE_GEO_DATA(response);
            this.geoData.geographiclayerDisplayName = response.display_name;
            // this.geoData.geographiclayerCodename = response.codename;
            this.geoData.columnX = response.column_x ? response.column_x : null;
            this.geoData.columnY = response.column_y ? response.column_y : null;
            this.geoData.crs = response.crs ? response.crs : null;
          }
          this.$emit('loading', false);
        } else if (this.mainResourceGeoData) {
          this.geoData.geographiclayerDisplayName = this.mainResourceGeoData.display_name;
          // this.geoData.geographiclayerCodename = this.mainResourceGeoData.codename;
          this.geoData.columnX = this.mainResourceGeoData.column_x ? this.mainResourceGeoData.column_x : null;
          this.geoData.columnY = this.mainResourceGeoData.column_y ? this.mainResourceGeoData.column_y : null;
          this.geoData.crs = this.mainResourceGeoData.crs ? this.mainResourceGeoData.crs : null;
        }
      } catch (err) {
        console.error(err);
      }
    },

    setMetaData(e) {
      this.metaData = e.form;
    },
    setGeoData(e) {
      this.geoData = e.form;
    },

    async updateResourceInfos() {
      try {
        if (this.isMetaDataChanged) {
          this.$emit('loading', true);
          await this.PATCH_RESOURCE({
            id: this.metaResource.id,
            data: {
              display_name: this.metaData.displayName,
              description: this.metaData.description,
              ...(this.metaData.kind && this.metaData.kind.id) && {
                kind_id: this.metaData.kind.id
              },
              lastUpdateDate: this.metaData.lastUpdateDate
            },
            datasetId: this.currentDataset.id,
          });
        }
        if (this.mainResourceGeographicLayer && this.isGeoDataChanged) {
          this.$emit('loading', true);
          // retrieve scenarios
          const scenarios = await resourcesAPI.getResourceGeographicLayerScenarios(this.mainResourceGeographicLayer.id);

          // then patch geographic layer
          await resourcesAPI.patchResourceGeographicLayer(
            this.mainResourceGeographicLayer['@uri'],
            { display_name: this.geoData.geographiclayerDisplayName },
          );

          // finally run scenario
          if (scenarios && scenarios.length) {
            const scenarioId = scenarios[0].id;
            await resourcesAPI.runResourceGeographicLayerScenario(this.mainResourceGeographicLayer.id, scenarioId);
          }

          // refresh data
          const response = await resourcesAPI.getResourceData(this.mainResourceGeographicLayer['@uri']);
          if (response) {
            this.SET_MAIN_RESOURCE_GEO_DATA(response);
            this.geoData.geographiclayerDisplayName = response.display_name;
            // this.geoData.geographiclayerCodename = response.codename;
          }
        }
        this.$emit('loading', false);
      } catch (err) {
        ErrorService.onError(err);
        this.$emit('loading', false);
      }
    },
  }
};
</script>

<style lang="less" scoped>
.accordion-button:not(.collapsed) {
  color: inherit;
}
.accordion-header {
  .accordion-button {
    font-weight: 700 !important;
    background-color: #0074cc16 !important;
  }
  .accordion-button:not(.collapsed) {
    color: inherit;
  }
}
.accordion-body {
  padding-left: 5px;
  background-color: #eff3f52d;
}
</style>