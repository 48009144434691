<template>
  <div class="dataset-right-sidebar">
    <DatasetCard
      :dataset="currentDataset"
      @set="$event => $emit('set-thumbnail', $event)"
    />
    <!-- BUTTONS -->
    <div class="side-buttons">
      <b-button
        variant="outline-secondary"
        @click="$router.push({ name: 'Datasets' })"
      >
        <i class="bi bi-box-arrow-left" />
        {{ $t('buttons.cancel') }}
      </b-button>
      <b-button
        variant="primary"
        style="float: right"
        @click="$emit('save')"
      >
        <i class="bi bi-floppy-fill" />
        {{ $t('datasetDetail.saveDataset') }}
      </b-button>
    </div>
    <!-- PUBLICATION -->
    <div
      :class="{ disabled: !currentDatasetPublishScenario || !currentDatasetUnpublishScenario }"
      class="sidebar-item"
    >
      <label>
        {{ $t('datasetDetail.publication') }}
      </label>
      <SwitchButton
        :active="{
          label: 'Publié dans le catalogue',
          value: currentDatasetPublishScenario ? currentDatasetPublishScenario.id : null
        }"
        :disabled="{
          label: 'Publication désactivée',
          value: currentDatasetUnpublishScenario ? currentDatasetUnpublishScenario.id : null
        }"
        :initial-value="currentDatasetPublishState"
        style="margin-bottom: 2rem;"
        @toggle="runDatasetPublishScenario"
      />
      <!-- <TwoLabelsSwitch
        :left="{
          label: capitalizeFirstLetter($t('words.unpublish')),
          value: currentDatasetUnpublishScenario ? currentDatasetUnpublishScenario.id : null
        }"
        :right="{
          label: capitalizeFirstLetter($t('words.publish')),
          value: currentDatasetPublishScenario ? currentDatasetPublishScenario.id : null
        }"
        :initial-value="currentDatasetPublishState"
        @toggle="runDatasetPublishScenario"
      /> -->
    </div>
    <!-- USERGROUP -->
    <div class="sidebar-item">
      <label>
        {{ $t('forms.datasets.organisation.label') }}
      </label>
      <SearchUsergroups
        v-model="usergroup"
        :types="['organisation', 'user-group']"
        :placeholder="$t('forms.datasets.organisation.help')"
        :usergroup="usergroup"
        :disabled="true"
        :reset-after="false"
        :limit="10"
        @select="setUsergroup"
        @close="setUsergroup"
        @remove="setUsergroup"
      />
    </div>
    <!-- RECORD TYPE -->
    <div class="sidebar-item">
      <label
        :class="isFieldRequired('record_type', formConfig.requiredFields)"
        style="margin-bottom: 0.5rem;"
      >
        {{ $t('datasetDetail.recordType') }}
      </label>
      <b-overlay
        :show="recordTypeBusy"
        rounded="lg"
        opacity="0.6"
      >
        <template #overlay>
          <div class="d-flex align-items-center">
            <b-spinner
              small
              type="grow"
              variant="secondary"
            />
            <b-spinner
              type="grow"
              variant="dark"
            />
            <b-spinner
              small
              type="grow"
              variant="secondary"
            />
            <span class="sr-only">Please wait...</span>
          </div>
        </template>
        <ValidationProvider
          v-slot="{ classes, errors }"
          :rules="isFieldRequired('record_type', formConfig.requiredFields)"
          :name="$t('datasetDetail.recordType')"
        >
          <div
            class="control"
            :class="classes"
          >
            <Multiselect
              v-model="recordType"
              :options="datasetsRecordTypes"
              track-by="choice"
              label="label"
              select-label=""
              selected-label=""
              deselect-label=""
              :searchable="false"
              :placeholder="$t('datasetDetail.recordType')"
              @select="setRecordType"
            />
            <span class="form-errors">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
      </b-overlay>
    </div>
    <!-- PARENT -->
    <div class="sidebar-item">
      <label>
        {{ $t('datasetDetail.parentDataset.title') }}
      </label>
      <b-overlay
        :show="parentLoading"
        rounded="lg"
        opacity="0.6"
      >
        <div
          :class="{
            none: !parent
          }"
          class="parent-dataset"
        >
          <span v-if="!parent">
            Aucune fiche associée
          </span>
          <div
            v-else-if="parent && parent.display_name"
            class="parent-dataset-display"
          >
            <div class="parent-dataset-display-name">
              {{ parent.display_name }}
            </div>
            <i
              class="bi bi-trash-fill text-danger"
              @click="removeParentDataset"
            />
          </div>
        </div>
        <b-button
          variant="outline-secondary"
          @click="isParentDatasetModalOpen = true"
        >
          <b-icon
            icon="diagram-3"
            font-scale="1.25"
          />
          {{ $t('datasetDetail.setParent').toUpperCase() }}
        </b-button>
      </b-overlay>
    </div>
    <div class="sidebar-item">
      <label style="margin-bottom: 0.5rem;">
        {{ $t('datasetDetail.permissions') }}
      </label>
      <b-overlay
        :show="permissionsBusy"
        rounded="lg"
        opacity="0.6"
      >
        <template #overlay>
          <div class="d-flex align-items-center">
            <b-spinner
              small
              type="grow"
              variant="secondary"
            />
            <b-spinner
              type="grow"
              variant="dark"
            />
            <b-spinner
              small
              type="grow"
              variant="secondary"
            />
            <span class="sr-only">Please wait...</span>
          </div>
        </template>
        <Multiselect
          v-model="permissionLevel"
          :options="permissionLevels"
          track-by="code"
          label="label"
          select-label=""
          selected-label=""
          deselect-label=""
          :searchable="false"
          :placeholder="$t('datasetDetail.permissions')"
          @select="askForChangePermissionLevel"
        />
      </b-overlay>
    </div>
    <div class="sidebar-item">
      <b-button
        variant="outline-secondary"
        @click="$router.push({
          name: 'DatasetPermissions',
          params: {
            id: currentDataset.id,
          }
        })"
      >
        <img
          src="@/assets/icons/access_unlock_open.svg"
          alt="Icon access unlock open"
        >
        {{ $t('datasetDetail.access') }}
      </b-button>
      <b-button
        variant="outline-secondary"
        @click="$router.push({
          name: 'Logs',
          params: {
            object: 'dataset',
            id: currentDataset.id,
          }
        })"
      >
        <b-icon
          icon="clock-history"
          font-scale="1.25"
        />
        {{ $t('words.activityFlow').toUpperCase() }}
      </b-button>
      <b-button
        variant="outline-secondary"
        @click="$router.push({
          name: 'Tasks',
          query: {
            dataset: currentDataset.id
          }
        })"
      >
        <b-icon
          icon="list-task"
          font-scale="1.25"
        />
        {{ $t('words.tasksHistory').toUpperCase() }}
      </b-button>
      <b-button
        variant="outline-secondary"
        @click="$emit('duplicate')"
      >
        <b-icon
          icon="files"
          font-scale="1.25"
        />
        {{ $t('buttons.duplicate').toUpperCase() }}
      </b-button>
    </div>
    <ParentDatasetModal
      :open="isParentDatasetModalOpen"
      :usergroup="usergroup"
      v-on="$listeners"
      @hide="isParentDatasetModalOpen = false"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import datasetsAPI from '@/api/datasetsAPI.js';

import DatasetCard from '@/components/Cards/DatasetCard.vue';
import Multiselect from 'vue-multiselect';
import ParentDatasetModal from '@/components/Modals/ParentDatasetModal.vue';
import SearchUsergroups from '@/components/Searchbars/SearchUsergroups.vue';
import SwitchButton from '@/components/Elements/SwitchButton.vue';

import { ValidationProvider, extend, configure, setInteractionMode } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Ce champ est requis'
});
configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
});
setInteractionMode('passive');

export default {
  name: 'DatasetRightSideBar',

  components: {
    DatasetCard,
    Multiselect,
    ParentDatasetModal,
    SearchUsergroups,
    SwitchButton,
    ValidationProvider
  },

  props: {
    usergroup: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },

  data() {
    return {
      parent: null,
      recordType: null,

      // loaders
      permissionsBusy: false,
      recordTypeBusy: false,
      parentLoading: false,

      // parent
      isParentDatasetModalOpen: false,

      // permissions
      permissionLevels: [
        {
          label: 'Donnée privée',
          code: 0
        },
        {
          label: 'Recherche',
          code: 1
        },
        {
          label: 'Consultation',
          code: 2
        },
        {
          label: 'Téléchargement',
          code: 3
        }
      ],
      permissionLevel: null,
      permissionsLevelToChange: null,
    };
  },

  computed: {
    ...mapState('datasets', [
      'currentDataset',
      'datasetsRecordTypes'
    ]),
    ...mapGetters('datasets', [
      'currentDatasetPublishState',
      'currentDatasetPublishScenario',
      'currentDatasetUnpublishScenario'
    ]),

    formConfig() {
      return this.$config.forms.dataset;
    },

    buildDatasetIndexScenarioId() {
      if (
        this.currentDataset &&
        this.currentDatasetPublishScenario &&
        this.currentDatasetPublishScenario.id &&
        this.currentDatasetUnpublishScenario &&
        this.currentDatasetUnpublishScenario.id
      ) {
        return this.currentDatasetPublishState ?
          this.currentDatasetPublishScenario.id :
          this.currentDatasetUnpublishScenario.id;
      }
      return null;
    },
  },

  watch: {
    currentDataset: {
      deep: true,
      async handler(newValue) {
        if (newValue && newValue.parent) {
          try {
            this.parentLoading = true;
            const parentDataset = await datasetsAPI.getDataset(newValue.parent);
            if (parentDataset) {
              this.parent = parentDataset;
            }
            this.parentLoading = false;
          } catch {
            this.parentLoading = false;
          }
        } else {
          this.parent = null;
        }
        if (newValue.record_type && newValue.record_type.value) {
          this.recordType = {
            choice: newValue.record_type.value,
            label: newValue.record_type.label,
          };
        }
        this.permissionLevel =
          this.permissionLevels.find(el => el.code === newValue.default_permission_level);
      }
    }
  },

  mounted() {
    // Watch modal closing event
    this.$root.$on('bv::modal::hide', () => {
      this.isParentDatasetModalOpen = false;
    });
  },

  methods: {
    ...mapMutations('modal', [
      'OPEN_MODAL',
    ]),
    ...mapActions('datasets', [
      'GET_DATASET_DETAIL',
      'PATCH_DATASET',
      'UPDATE_CURRENT_DATASET_USERGROUP'
    ]),
    setRecordType(e) {
      this.recordType = e ? e : null;
      this.updateDatasetRecordType();
      this.$emit('set-recordtype', e);
    },
    setUsergroup(e) {
      if (e !== this.usergroup) {
        this.UPDATE_CURRENT_DATASET_USERGROUP(e);
      }
      this.usergroup = e;
      this.$emit('set-usergroup', e);
    },
    async updateDatasetRecordType() {
      try {
        const data = {
          record_type_value: this.recordType ? this.recordType.choice : null
        };
        this.recordTypeBusy = true;
        await this.PATCH_DATASET({
          id: this.currentDataset.id,
          data: data
        });
        if (this.buildDatasetIndexScenarioId) {
          await datasetsAPI.buildDatasetIndex(this.$route.params.id, this.buildDatasetIndexScenarioId);
        }
        await this.GET_DATASET_DETAIL(this.$route.params.id);
        this.recordTypeBusy = false;
      } catch (err) {
        console.error(err);
        this.recordTypeBusy = false;
      }
    },

    askForChangePermissionLevel(e) {
      this.permissionsLevelToChange = e;
      this.OPEN_MODAL({
        modal: 'confirmation',
        open: true,
        title: this.$t('modals.changeDatasetPermissions.title'),
        content: this.$t('modals.changeDatasetPermissions.content'),
        trigger: this.changePermissionLevel
      });
    },

    async changePermissionLevel() {
      try {
        const data = {
          default_permission_level: this.permissionsLevelToChange.code
        };
        this.permissionsBusy = true;
        await this.PATCH_DATASET({
          id: this.currentDataset.id,
          data: data
        });
        this.permissionsLevelToChange = null;
        await this.GET_DATASET_DETAIL(this.$route.params.id);
        this.permissionsLevelToChange = null;
        this.permissionsBusy = false;
      } catch (err) {
        console.error(err);
        this.permissionsBusy = false;
      }
    },

    async runDatasetPublishScenario(scenarioId) {
      this.$emit('publish', scenarioId);
    },

    async removeParentDataset() {
      try {
        const data = {
          parent: null
        };
        this.parentLoading = true;
        await this.PATCH_DATASET({
          id: this.currentDataset.id,
          data: data
        });
        if (this.buildDatasetIndexScenarioId) {
          await datasetsAPI.buildDatasetIndex(this.$route.params.id, this.buildDatasetIndexScenarioId);
        }
        await this.GET_DATASET_DETAIL(this.$route.params.id);
        this.parentLoading = false;
      } catch (err) {
        console.error(err);
        this.parentLoading = false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.dataset-right-sidebar {
  position: sticky;
  top: 11%;
  padding: 0;
  height: 50%;

  .side-buttons {
    margin: 1em auto 1em;
    width: 80%;
    display: flex;
    flex-flow: wrap-reverse;
    justify-content: space-between;
    font-size: 1em;
    button {
      i.bi {
        margin-right: 0.5rem;
      }
    }
    button.btn-primary {
      border: 2px solid #9BD0FF;
      border-radius: 8px;
    }
    button.btn-outline-secondary {
      background-color: #F7F8FA;
      border: 2px solid #A9B2B9;
      border-radius: 8px;
      color: #2F3234;
    }
    button.btn-outline-secondary:hover {
      color: white;
      background-color: #4b4b4b;
    }
  }

  .sidebar-item {
    width: 80%;
    margin: 0 10% 1em;
    label {
      font-size: 0.88rem;
    }
    .parent-dataset {
      text-align: center;
      margin: 0.5rem 0;
      font-weight: bold;
      font-size: 1.1rem;
      .parent-dataset-display {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .parent-dataset-display-name {
          overflow-x: hidden;
          white-space: pre-line;
          text-overflow: ellipsis;
          text-align: justify;
          margin-right: 1rem;
        }
        i.bi-trash-fill {
          font-size: 1.5rem;
          cursor: pointer;
        }
        i.bi-trash-fill:hover {
          transform: scale(1.2);
        }
      }
    }
    .parent-dataset.none {
      font-style: italic;
      color: grey;
    }
    .btn {
      width: 100%;
      font-weight: 500;
      font-size: 1.2em;
      background-color: #F7F8FA;
      border: 2px solid #A9B2B9;
      border-radius: 8px;
      color: #2F3234;
      img {
        height: 1.2em;
        margin-bottom: 0.3em;
        margin-right: 0.2em;
        filter:
          brightness(0)
          saturate(100%)
          invert(44%)
          sepia(8%)
          saturate(483%)
          hue-rotate(167deg)
          brightness(100%)
          contrast(91%);
        -webkit-filter:
          brightness(0)
          saturate(100%)
          invert(44%)
          sepia(8%)
          saturate(483%)
          hue-rotate(167deg)
          brightness(100%)
          contrast(91%);
        -moz-filter:
          brightness(0)
          saturate(100%)
          invert(44%)
          sepia(8%)
          saturate(483%)
          hue-rotate(167deg)
          brightness(100%)
          contrast(91%);
      }
      .b-icon {
        margin-right: 0.5rem;
      }
    }
    .btn:hover {
      color: white;
      background-color: #4b4b4b;
      img {
        filter: brightness(0%) invert(100%);
        -webkit-filter: brightness(0%) invert(100%);
        -moz-filter: brightness(0%) invert(100%);
      }
    }
    .btn:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
  .sidebar-item.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
</style>