<template>
  <div>
    <b-overlay
      :show="loading"
      :variant="'white'"
      :opacity="0.7"
      :blur="'2px'"
      rounded="sm"
      no-wrap
    />
    <div
      v-if="loading && (!currentDatasetAnnexResources || !currentDatasetAnnexResources.length)"
      style="height: 3rem;"
    />
    <div
      v-if="!loading && (!currentDatasetAnnexResources || !currentDatasetAnnexResources.length)"
      class="form-row"
    >
      <div
        class="form-group col"
        style="margin-bottom: 0;"
      >
        <p style="font-size: 0.9rem;">
          {{ $t('datasetDetail.annexes.empty') }}
        </p>
      </div>
    </div>
    <div
      v-for="annexResource of annexResources"
      :key="annexResource.id"
      class="annex_resource"
    >
      <b-overlay
        :show="isAnnexeLoading(annexResource.id) || annexResource.id === annexLoadingId"
        :variant="'white'"
        :opacity="0.7"
        :blur="'2px'"
        rounded="sm"
        no-wrap
        style="z-index: 1050;"
      />
      <ResourceProcessingAlert
        v-for="process of currentDatasetAnnexResourceProcessings(annexResource.id)"
        :key="process.id"
        :process="process"
        :resource-id="annexResource.id"
        :task-id="
          currentDatasetAnnexResources.find(el => el.resource.id === annexResource.id) &&
            currentDatasetAnnexResources.find(el => el.resource.id === annexResource.id)._processing ?
              currentDatasetAnnexResources.find(el => el.resource.id === annexResource.id)._processing.task_id :
              null
        "
      />
      <ResourceEditor
        v-if="
          annexResourcesDatasetRelation &&
            annexResourcesDatasetRelation.length &&
            annexResourcesDatasetRelation[0].id
        "
        :key="`annex-${annexResource.codename}`"
        :meta-resource="annexResource"
        :resource="annexesData.find(el => el.id === annexResource.rel[0].id)"
        :is-main="false"
        :disabled="isCurrentDatasetAnnexResourcePending(annexResource.id)"
        v-on="$listeners"
        @delete="isDeleteResourceModalOpen = true"
      />
      <div
        v-if="annexesData.length && annexesData.find(el => el.id === annexResource.rel[0].id)"
        :class="{
          disabled: isCurrentDatasetAnnexResourcePending(annexResource.id)
        }"
        class="resource__delete"
      >
        <div />
        <b-button
          variant="danger"
          @click="isDeleteResourceModalOpen = true"
        >
          {{ $t(`buttons.delete`) }}
        </b-button>
      </div>
      <DeleteResourceModal
        :open="isDeleteResourceModalOpen"
        :resource="annexesData.find(el => el.id === annexResource.rel[0].id)"
        @hide="isDeleteResourceModalOpen = false"
        @delete="deleteResource(annexResource.id)"
      />
    </div>
  </div>
</template>

<script>
import resourcesAPI from '@/api/resourcesAPI.js';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import DeleteResourceModal from '@/components/Resource/modals/DeleteResourceModal.vue';
import ResourceEditor from '@/components/Resource/ResourceEditor.vue';
import ResourceProcessingAlert from '@/components/Resource/ResourceProcessingAlert.vue';

export default {
  name: 'Annexes',

  components: {
    DeleteResourceModal,
    ResourceEditor,
    ResourceProcessingAlert
  },

  props: {
    loadingId: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      loading: false,
      annexLoadingId: null,
      isDeleteResourceModalOpen: false,
      annexesData: []
    };
  },

  computed: {
    ...mapState('resources', [
      'annexResources',
      'annexResourcesDatasetRelation'
    ]),
    ...mapState('datasets', [
      'currentDataset'
    ]),
    ...mapGetters('resources', [
      'currentDatasetAnnexResources',
      'isCurrentDatasetAnnexResourcePending',
      'currentDatasetAnnexResourceProcessings'
    ])
  },

  watch: {
    loadingId(newValue) {
      this.annexLoadingId = newValue;
    },

    currentDatasetAnnexResources: {
      deep: true,
      async handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          await this.getAnnexesData();
        }
      }
    }
  },

  async created() {
    await this.getAnnexesData();
  },

  methods: {
    ...mapMutations('resources', [
      'SET_ANNEX_RESOURCE'
    ]),
    ...mapActions('resources', [
      'GET_ANNEX_RESOURCE',
      'DELETE_RESOURCE_FROM_DATASET',
      'DELETE_RESOURCE'
    ]),

    isAnnexeLoading(id) {
      if (id) {
        return this.annexLoadingId === id;
      }
      return false;
    },

    isAnnexeProcessingPending(id) {
      return this.currentDataset.resource_annexes &&
        this.currentDataset.resource_annexes.length &&
        this.currentDataset.resource_annexes.find(el => el.id === id) &&
        this.currentDataset.resource_annexes.find(el => el.id === id)._processing &&
        this.currentDataset.resource_annexes.find(el => el.id === id)._processing.state &&
        this.currentDataset.resource_annexes.find(el => el.id === id)._processing.state === 'PENDING';
    },

    async getAnnexesData() {
      try {
        this.loading = true;
        this.SET_ANNEX_RESOURCE(null);
        if (this.currentDatasetAnnexResources && this.currentDatasetAnnexResources.length) {
          for (const currentDatasetAnnexResource of this.currentDatasetAnnexResources) {
            this.annexLoadingId = currentDatasetAnnexResource.id;
            await this.GET_ANNEX_RESOURCE({
              id: currentDatasetAnnexResource.resource.id,
              resourceToDatasetId: currentDatasetAnnexResource.id
            });
            this.annexLoadingId = null;
          }
          if (this.annexResources && this.annexResources.length) {
            this.annexesData.splice(0, this.annexesData.length);
            for (const annexResource of this.annexResources) {
              if (annexResource.rel && annexResource.rel.length && annexResource.rel[0]['@uri']) {
                const source = await resourcesAPI.getResourceData(annexResource.rel[0]['@uri']);
                const index = this.annexesData.findIndex(el => el.id === source.id);
                if (index === -1) {
                  this.annexesData.push(source);
                }
              }
            }
          }
        }
        this.loading = false;
      } catch (err) {
        console.error(err);
        this.loading = false;
        this.annexLoadingId = null;
      }
    },

    deleteResource(id) {
      const annex = this.annexResources.find(el => el.id === id);
      this.annexLoadingId = id;
      this.DELETE_RESOURCE_FROM_DATASET({
        resourceToDatasetId: this.annexResourcesDatasetRelation.find(el => el.resource.id === id).id,
        resource: annex
      })
        .then(() => {
          const annexResource = this.annexResources.find(el => el.id === id);
          this.DELETE_RESOURCE(annexResource)
            .then(() => {
              this.SET_ANNEX_RESOURCE(null);
              this.annexLoadingId = null;
            })
            .catch((err) => {
              console.error(err);
              this.annexLoadingId = null;
            });
          this.$emit('delete');
        })
        .catch((err) => {
          console.error(err);
          this.annexLoadingId = null;
        });
    }
  }
};
</script>
