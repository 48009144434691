var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.loading,"variant":'white',"opacity":0.7,"blur":'2px',"rounded":"sm","no-wrap":""}}),(_vm.loading && (!_vm.currentDatasetAnnexResources || !_vm.currentDatasetAnnexResources.length))?_c('div',{staticStyle:{"height":"3rem"}}):_vm._e(),(!_vm.loading && (!_vm.currentDatasetAnnexResources || !_vm.currentDatasetAnnexResources.length))?_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col",staticStyle:{"margin-bottom":"0"}},[_c('p',{staticStyle:{"font-size":"0.9rem"}},[_vm._v(" "+_vm._s(_vm.$t('datasetDetail.annexes.empty'))+" ")])])]):_vm._e(),_vm._l((_vm.annexResources),function(annexResource){return _c('div',{key:annexResource.id,staticClass:"annex_resource"},[_c('b-overlay',{staticStyle:{"z-index":"1050"},attrs:{"show":_vm.isAnnexeLoading(annexResource.id) || annexResource.id === _vm.annexLoadingId,"variant":'white',"opacity":0.7,"blur":'2px',"rounded":"sm","no-wrap":""}}),_vm._l((_vm.currentDatasetAnnexResourceProcessings(annexResource.id)),function(process){return _c('ResourceProcessingAlert',{key:process.id,attrs:{"process":process,"resource-id":annexResource.id,"task-id":_vm.currentDatasetAnnexResources.find(function (el) { return el.resource.id === annexResource.id; }) &&
          _vm.currentDatasetAnnexResources.find(function (el) { return el.resource.id === annexResource.id; })._processing ?
            _vm.currentDatasetAnnexResources.find(function (el) { return el.resource.id === annexResource.id; })._processing.task_id :
            null}})}),(
        _vm.annexResourcesDatasetRelation &&
          _vm.annexResourcesDatasetRelation.length &&
          _vm.annexResourcesDatasetRelation[0].id
      )?_c('ResourceEditor',_vm._g({key:("annex-" + (annexResource.codename)),attrs:{"meta-resource":annexResource,"resource":_vm.annexesData.find(function (el) { return el.id === annexResource.rel[0].id; }),"is-main":false,"disabled":_vm.isCurrentDatasetAnnexResourcePending(annexResource.id)},on:{"delete":function($event){_vm.isDeleteResourceModalOpen = true}}},_vm.$listeners)):_vm._e(),(_vm.annexesData.length && _vm.annexesData.find(function (el) { return el.id === annexResource.rel[0].id; }))?_c('div',{staticClass:"resource__delete",class:{
        disabled: _vm.isCurrentDatasetAnnexResourcePending(annexResource.id)
      }},[_c('div'),_c('b-button',{attrs:{"variant":"danger"},on:{"click":function($event){_vm.isDeleteResourceModalOpen = true}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.delete"))+" ")])],1):_vm._e(),_c('DeleteResourceModal',{attrs:{"open":_vm.isDeleteResourceModalOpen,"resource":_vm.annexesData.find(function (el) { return el.id === annexResource.rel[0].id; })},on:{"hide":function($event){_vm.isDeleteResourceModalOpen = false},"delete":function($event){return _vm.deleteResource(annexResource.id)}}})],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }